// This optional code is used to register a service worker.
// register() is not called by default.

import { forget } from '@xylabs/sdk-js'

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.0/8 are considered localhost for IPv4.
    || /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})){3}$/.test(window.location.hostname),
  )

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void
  onUpdate?: (registration: ServiceWorkerRegistration) => void
}

const registerValidSW = async (swUrl: string, config?: Config) => {
  console.log('ServiceWorker', 'registerValidSW')
  try {
    const registration = await navigator.serviceWorker.register(swUrl)
    registration.addEventListener('updatefound', (): void => {
      const installingWorker = registration.installing
      if (installingWorker == null) {
        return
      }
      installingWorker.addEventListener('statechange', (): void => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // At this point, the updated precached content has been fetched,
            // but the previous service worker will still serve the older
            // content until all client tabs are closed.
            console.log('New content is available and will be used when all ' + 'tabs for this page are closed. See https://bit.ly/CRA-PWA.')

            // Execute callback
            if (config && config.onUpdate) {
              config.onUpdate(registration)
            }
          } else {
            // At this point, everything has been precached.
            // It's the perfect time to display a
            // "Content is cached for offline use." message.
            console.log('Content is cached for offline use.')

            // Execute callback
            if (config && config.onSuccess) {
              config.onSuccess(registration)
            }
          }
        }
      })
    })
  } catch (ex) {
    console.error('Error during service worker registration:', ex)
  }
}

const checkValidServiceWorker = async (swUrl: string, config?: Config) => {
  console.log('ServiceWorker', 'checkValidServiceWorker', swUrl)
  // Check if the service worker can be found. If it can't reload the page.
  try {
    const response = await fetch(swUrl, {
      headers: {
        'Service-Worker': 'script',
      },
    })

    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type')
    if (response.status === 404 || (contentType != null && !contentType.includes('javascript'))) {
      console.log('ServiceWorker', 'checkValidServiceWorker', 'failed', response.status, contentType)
      // No service worker found. Probably a different app. Reload the page.
      const registration = await navigator.serviceWorker.ready
      await registration.unregister()
      window.location.reload()
    } else {
      console.log('ServiceWorker', 'checkValidServiceWorker', 'registerValidSW')
      // Service worker found. Proceed as normal.
      await registerValidSW(swUrl, config)
    }
  } catch {
    console.log('No internet connection found. App is running in offline mode.')
  }
}

const onWindowLoad = async (config?: Config) => {
  console.log('ServiceWorker', 'onWindowLoad')
  const swUrl = `${process.env.PUBLIC_URL}/bios.js`

  if (isLocalhost()) {
    // This is running on localhost. Let's check if a service worker still exists or not.
    await checkValidServiceWorker(swUrl, config)

    // Add some additional logging to localhost, pointing developers to the
    // service worker/PWA documentation.
    try {
      await navigator.serviceWorker.ready
      console.log('This web app is being served cache-first by a service ' + 'worker. To learn more, visit https://bit.ly/CRA-PWA')
    } catch (ex) {
      console.error('Error during service worker registration:', ex)
    }
  } else {
    // Is not localhost. Just register service worker
    await registerValidSW(swUrl, config)
  }
}

export const register = (config?: Config) => {
  console.log('ServiceWorker', 'register')
  if ('serviceWorker' in navigator) {
    if (process.env.NODE_ENV === 'production') {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(process.env.PUBLIC_URL || '', window.location.href)
      if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebook/create-react-app/issues/2374
        console.log('ServiceWorker', 'unsupported origin')
        return
      }
    }

    window.addEventListener('load', () => {
      forget(onWindowLoad(config))
    })
  }
}

export const unregister = async () => {
  console.log('ServiceWorker', 'unregister')
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready
    try {
      await registration.unregister()
    } catch (ex) {
      console.log('Unregister service worker excepted', ex)
    }
  }
}
