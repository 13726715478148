import { CircularProgress } from '@mui/material'
import { Fbq, Gtag } from '@xylabs/react-pixel'
import type { PropsWithChildren } from 'react'
import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom'

const OsApp = lazy(() => import(/* webpackChunkName: "OsApp" */ '@xyo-network/os-react-app'))

// eslint-disable-next-line import-x/no-internal-modules
const View = lazy(() => import(/* webpackChunkName: "View" */ '@xyo-network/os-react-app/view'))

export const LazyRoute: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Suspense fallback={<CircularProgress />}>
    {children}
  </Suspense>
)

Fbq.init('1653480428329003')
Gtag.init('G-SLKCXVB59J', 'AW-989965544', ['node.xyo.network'])

console.log('import.meta.env', import.meta.env)

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/view/*"
          element={
            <LazyRoute><View mainNetwork={import.meta.env.VITE_MAIN_NETWORK} mainNetworkUrl={import.meta.env.VITE_MAIN_NETWORK_URL} /></LazyRoute>
          }
        />
        <Route
          path="*"
          element={(
            <LazyRoute>
              <OsApp
                apiDomain={import.meta.env.VITE_API_DOMAIN}
                mainNetwork={import.meta.env.VITE_MAIN_NETWORK}
                mainNetworkUrl={import.meta.env.VITE_MAIN_NETWORK_URL}
                rebDomain={import.meta.env.VITE_REB_DOMAIN}
                rebOrganizationId={import.meta.env.VITE_REB_ORGANIZATION_ID}
                rebPubApikey={import.meta.env.VITE_REB_PUB_APIKEY}
                rollbarEnvironment={import.meta.env.VITE_ROLLBAR_ENVIRONMENT}
                rollbarToken={import.meta.env.VITE_ROLLBAR_TOKEN}
                xnsNetwork={import.meta.env.VITE_XNS_NETWORK}
                xnsNodeUrl={import.meta.env.VITE_XNS_NODE_URL}
              />
            </LazyRoute>
          )}
        />
      </Routes>
    </BrowserRouter>
  )
}
